import { create } from "zustand";
import { LeadPersonalizationSource, MessageType } from "../enum/lead.enum";

interface ILeadStore {
  source: LeadPersonalizationSource;
  setSource: (source: LeadPersonalizationSource) => void;
  messageType: MessageType;
  setMessageType: (msgType: MessageType) => void;
}

export const useLeadStore = create<ILeadStore>((set) => ({
  messageType: MessageType.FirstLine,
  setMessageType: (messageType: MessageType) => set({ messageType }),
  source: LeadPersonalizationSource.LinkedIn,
  setSource: (source: LeadPersonalizationSource) => set({ source }),
}));
