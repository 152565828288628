import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import ComingSoonImg from "assets/img/campaign/comingsoon.svg";

function ComingSoon() {
  const textColorSecondary = "gray.600";
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "100px", md: "80px", xl: "120px" }} mt={5}>
      <Flex justify="center" align="center" h="full" direction="column">
        <Flex justify="center" align="center" h="full" direction="column">
          <Image
            src={ComingSoonImg}
            alt="Coming soon"
            maxW={{ base: "100%", md: "30rem" }}
          />
          <Box textAlign="center" mt={10}>
            <Text color={textColor} fontSize="4xl" fontWeight="bold">
              Launching Soon
            </Text>
            <Text
              color={textColorSecondary}
              fontWeight="500"
              fontSize="xl"
              mt={3}
            >
              Elevate your email campaigns with personalized email for each
              lead, <br /> boosting response rates by 8x.
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default ComingSoon;
