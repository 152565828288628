import {
  Box,
  Flex,
  PopoverTrigger as OrigPopoverTrigger,
  Spinner,
  Text,
} from "@chakra-ui/react";
import bannerImg from "assets/img/auth/banner.png";
import Card from "components/card/Card";
import MessageDisplayer from "components/messageDisplayer/MessageDisplayer";
import CreditProvider from "layouts/admin/CreditProvider";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetUserQuery } from "views/auth/query/user.query";
import { MessageType } from "../new-email/enum/lead.enum";
import Banner from "./components/Banner";
import { ILead } from "./interfaces/lead.interface";
import { ctaMapper } from "./mapper/cta.mapper";
import { useGetLeadByIdQuery } from "./query/lead.query";

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

function ViewLead() {
  const textColorSecondary = "gray.600";

  const { id = "" } = useParams() as { id?: string };

  const { data: lead, isLoading: isLeadLoading } = useGetLeadByIdQuery({ id });
  const { data: user } = useGetUserQuery();

  const identityMessage = lead?.ctaMessage;
  const cta = ctaMapper[lead?.ctaType];
  const leadCurrentJob = lead?.profile?.data?.position_groups?.[0];
  const leadCurrentJobText = leadCurrentJob
    ? `${leadCurrentJob?.profile_positions?.[0]?.title} at ${leadCurrentJob?.company?.name}`
    : "";

  const getMessageData = useCallback(() => {
    if (lead?.personalisedMessages && lead.personalisedMessages.length)
      return {
        messageType: MessageType.FirstLine,
        data: lead?.personalisedMessages,
      };
    else if (lead?.psMessages && lead.psMessages.length)
      return { messageType: MessageType.PS, data: lead?.psMessages };
    else if (
      lead?.firstLinePostScriptMessages &&
      lead.firstLinePostScriptMessages.length
    )
      return {
        messageType: MessageType.FirstLineAndPS,
        data: lead?.firstLinePostScriptMessages,
      };
  }, [lead, MessageType]);

  const messageData = useMemo(() => getMessageData(), [lead, getMessageData]);

  if (isLeadLoading || !lead)
    return (
      <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
        <Box maxW={{ lg: "850px" }} m="auto">
          <Card
            gap={5}
            p={5}
            fontWeight={500}
            mt={5}
            display="flex"
            justifyContent="center"
          >
            <Text textAlign="center" fontSize="lg" fontWeight="bold">
              Hold tight! Info's en route! 🚀
            </Text>
            <Flex justifyContent="center">
              <Spinner color="brand.500" size="md" />
            </Flex>
          </Card>
        </Box>
      </Box>
    );

  return (
    <CreditProvider>
      <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
        <Box maxW={{ lg: "850px" }} m="auto">
          <Banner
            name={
              lead?.profile?.data?.first_name ||
              lead?.profile?.data?.last_name ||
              lead?.profile?.identifier ||
              ""
            }
            job={leadCurrentJobText}
            banner={bannerImg}
          />
          <Card gap={5} p={5} fontWeight={500} mt={5}>
            <MessageDisplayer
              messages={messageData?.data}
              messageType={messageData?.messageType}
            />
            <Text color={textColorSecondary}>{identityMessage}</Text>
            <Text color={textColorSecondary}>{cta}</Text>
          </Card>
        </Box>
      </Box>
    </CreditProvider>
  );
}

export default ViewLead;
