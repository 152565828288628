import Card from "components/card/Card";
import SelectCard from "components/selectCard/SelectCard";
import { FaLinkedin } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { LeadPersonalizationSource } from "../../enum/lead.enum";
import { useLeadStore } from "../../store/lead.store";

function Source({ next }: { next: () => void }) {
  const { setSource } = useLeadStore((state) => state);

  function handleSetSource(source: LeadPersonalizationSource) {
    setSource(source);
    next();
  }

  return (
    <Card mt={{ lg: 10 }} gap={5}>
      <SelectCard
        title="Personal LinkedIn"
        body="Use your prospects' LinkedIn profile as input"
        icon={FaLinkedin}
        onClick={() => handleSetSource(LeadPersonalizationSource.LinkedIn)}
      />
      <SelectCard
        title="Company Domain"
        body="Use data from your prospects' company website as input"
        icon={TbWorldWww}
        onClick={() => handleSetSource(LeadPersonalizationSource.Website)}
      />
    </Card>
  );
}

export default Source;
