import { Icon } from "@chakra-ui/react";
import { MdEmail, MdLock } from "react-icons/md";
import { SiCampaignmonitor } from "react-icons/si";
import Campaigns from "views/admin/campaigns/Campaigns";
import ViewCampaign from "views/admin/campaigns/ViewCampaign";
import ComingSoon from "views/admin/email-campaign/ComingSoon";
import ViewLead from "views/admin/leads/ViewLead";
import { ImUsers } from "react-icons/im";

// Admin Imports
import NewEmail from "views/admin/new-email";
import Pricing from "views/admin/pricing";
import ForgotPassword from "views/auth/forgot-password";

// Auth Imports
import SignIn from "views/auth/sign-in";
import SignUp from "views/auth/sign-up";
import UpdatePassword from "views/auth/update-passwrd";

const routes = [
  {
    name: "Quick Personalization",
    heading: "Quick Personalization",
    layout: "",
    path: "/",
    icon: <Icon as={SiCampaignmonitor} width="20px" height="20px" color="inherit" />,
    component: NewEmail,
    exact: true,
  },
  {
    name: "View Lead",
    layout: "",
    path: "/lead/:id",
    icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
    component: ViewLead,
    disable: true,
  },
  {
    name: "Leads",
    layout: "",
    path: "/leadlists",
    icon: <Icon as={ImUsers} width="20px" height="20px" color="inherit" />,
    component: Campaigns,
  },
  {
    name: "Campaigns",
    layout: "",
    path: "/campaigns",
    icon: (
      <Icon as={MdEmail} width="20px" height="20px" color="inherit" />
    ),
    component: ComingSoon,
  },
  {
    name: "View Leads",
    layout: "",
    path: "/leadlist/:id",
    icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
    component: ViewCampaign,
    disable: true,
  },
  {
    name: "Pricing",
    layout: "",
    path: "/pricing",
    icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
    component: Pricing,
    disable: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignIn,
    disable: true,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUp,
    disable: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    disable: true,
  },
  {
    name: "Update Password",
    layout: "/auth",
    path: "/update-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: UpdatePassword,
    disable: true,
  },
];

export default routes;
