import {
  Badge,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";

import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import { rankItem } from "@tanstack/match-sorter-utils";
import Card from "components/card/Card";
import MessageSelector from "components/messageSelector/MessageSelector";
import CreditProvider from "layouts/admin/CreditProvider";
import { useParams } from "react-router-dom";
import { ILead } from "../leads/interfaces/lead.interface";
import { LeadStatus, MessageType } from "../new-email/enum/lead.enum";
import {
  useGenerateCsvQuery,
  useGetCampaignByIdQuery,
} from "./query/campaign.query";

const columnHelper = createColumnHelper<ILead>();

export default function ViewCampaignDetail() {
  const { id = "" } = useParams() as { id?: string };

  const { data } = useGetCampaignByIdQuery({ id });
  const [generateSuccess, setGenerateSuccess] = useState(false);
  const { generateCsv, isGeneratingCsv } = useGenerateCsvQuery({
    setSuccess: setGenerateSuccess,
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [globalFilter, setGlobalFilter] = useState("");

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const getNameAccessor = (lead: ILead) => {
    if (lead.profile?.identifier)
      return {
        username: lead.profile?.data?.profile_id || lead?.profile?.identifier,
        name:
          lead?.metadata?.name || lead.profile?.data?.first_name || "Unknown",
        status: lead?.status,
      };
    else
      return {
        username: lead?.metadata?.linkedinUsername,
        name: "Unknown",
        status: lead?.status,
      };
  };

  const getMessageAccessor = (lead: ILead) => {
    if (lead?.personalisedMessages && lead.personalisedMessages.length)
      return {
        messageType: MessageType.FirstLine,
        data: lead?.personalisedMessages,
        status: lead?.status,
      };
    else if (lead?.psMessages && lead.psMessages.length)
      return {
        messageType: MessageType.PS,
        data: lead?.psMessages,
        status: lead?.status,
      };
    else if (
      lead?.firstLinePostScriptMessages &&
      lead.firstLinePostScriptMessages.length
    )
      return {
        messageType: MessageType.FirstLineAndPS,
        data: lead?.firstLinePostScriptMessages,
        status: lead?.status,
      };
  };

  const columns = [
    columnHelper.accessor(getNameAccessor, {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.getValue()?.status !== LeadStatus.Pending ? (
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              textTransform="capitalize"
            >
              {info.getValue()?.name}
              <br />
              <Text
                color={textColorSecondary}
                fontSize="sm"
                fontWeight="700"
                textTransform="lowercase"
              >
                {info.getValue()?.username}
              </Text>
            </Text>
          ) : (
            <Spinner color="brand.500" size="sm" />
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor(getMessageAccessor, {
      id: "messages",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          PERSONALIZATION
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.getValue()?.status !== LeadStatus.Pending ? (
            <>
              {info.getValue()?.data?.length ? (
                <MessageSelector messages={info.getValue()?.data} />
              ) : (
                <Text
                  color={textColor}
                  fontSize="sm"
                  fontWeight="700"
                  textTransform="capitalize"
                >
                  -
                </Text>
              )}
            </>
          ) : (
            <Spinner color="brand.500" size="sm" />
          )}
        </Flex>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {info.getValue() === LeadStatus.Pending && (
            <Badge colorScheme="purple">Pending</Badge>
          )}
          {info.getValue() === LeadStatus.Complete && (
            <Badge colorScheme="green">Success</Badge>
          )}
          {info.getValue() === LeadStatus.Failed && (
            <Badge colorScheme="red">Failed</Badge>
          )}
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: data?.leads ?? [],
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const downloadCsv = () => {
    const link = document.createElement("a");
    link.download = data?.name;
    link.href = data?.completedCsvFile?.url;
    link.click();
  };

  const renderCtaButton = () => {
    const isAnySuccessLeads = data?.leads?.some(
      (lead) => lead.status === LeadStatus.Complete
    );
    const isAnyPendingLeads = data?.leads?.some(
      (lead) => lead.status === LeadStatus.Pending
    );
    if (data?.status === "processing")
      return (
        <Button
          variant="brand"
          fontWeight="500"
          fontSize="sm"
          width="min-content"
          disabled
        >
          Generating
        </Button>
      );
    else if (
      data?.csvFile?._id &&
      !data?.completedCsvFile?._id &&
      isAnySuccessLeads &&
      !isAnyPendingLeads
    )
      return (
        <Button
          variant="brand"
          fontWeight="500"
          fontSize="sm"
          type="submit"
          loadingText="Generating"
          width="min-content"
          isLoading={isGeneratingCsv}
          onClick={() => generateCsv(id)}
          disabled={generateSuccess}
        >
          Generate Your CSV
        </Button>
      );
    else if (data?.completedCsvFile?._id)
      return (
        <Button
          variant="brand"
          fontWeight="500"
          fontSize="sm"
          type="submit"
          loadingText="Downloading"
          width="min-content"
          onClick={downloadCsv}
        >
          Download
        </Button>
      );
    else return <></>;
  };

  return (
    <CreditProvider>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        mb={{ base: "30px", md: "60px" }}
        mt={{ base: "130px", md: "80px", xl: "100px" }}
      >
        <Flex
          px="25px"
          mb="8px"
          justifyContent="space-between"
          align="center"
          gap={10}
        >
          <Text
            color={textColor}
            fontSize="22px"
            mb="4px"
            fontWeight="700"
            lineHeight="100%"
          >
            {data?.name}
          </Text>
          <Flex direction="column" alignItems="flex-end">
            {renderCtaButton()}
            {generateSuccess && (
              <Text fontSize="sm" mt={1}>
                Generating the output CSV file may take a few minutes. Please
                check back on the website after some time for the download link.
              </Text>
            )}
          </Flex>
        </Flex>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </CreditProvider>
  );
}
