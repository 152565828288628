import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdEmail, MdInfo } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { tw } from "twind";
import { DefaultCampaign } from "views/admin/campaigns/enum/campaign.enum";
import { useGetAllCampaignsQuery } from "views/admin/campaigns/query/campaign.query";
import { ICreateLeadPayload } from "views/admin/leads/interfaces/lead.interface";
import { useCreateLeadQuery } from "../../../leads/query/lead.query";
import { LeadPersonalizationSource } from "../../enum/lead.enum";
import { ILeadField } from "../../interfaces/lead.interface";
import { useLeadStore } from "../../store/lead.store";
import { extractLinkedInUsername } from "../../utils/string.util";
import LinkedinField from "../sub-components/LinkedinField";
import WebsiteField from "../sub-components/WebsiteField";

function Linkedin() {
  const { data: campaigns } = useGetAllCampaignsQuery();
  const history = useHistory();
  const { source, messageType, setSource } = useLeadStore((state) => state);

  const formRegistry = useForm<ILeadField>();

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = formRegistry;

  const resetForm = () => {
    reset();
  };

  const { createLead, isCreatingLead } = useCreateLeadQuery({
    history,
    resetForm,
  });

  async function onSubmit(data: ILeadField) {
    let leadData: string;
    if (source === LeadPersonalizationSource.LinkedIn)
      leadData = extractLinkedInUsername(data?.linkedin);
    else if (source === LeadPersonalizationSource.Website)
      leadData = data?.website;

    const campaign = campaigns.find((cam) => cam?.default);
    const payload: ICreateLeadPayload = {
      data: leadData,
      campaignId: campaign?._id,
      source,
      messageType,
    };

    createLead(payload);
  }

  const leadSourceComponentMapper = {
    [LeadPersonalizationSource.LinkedIn]: (
      <LinkedinField formState={formRegistry} />
    ),
    [LeadPersonalizationSource.Website]: (
      <WebsiteField formState={formRegistry} />
    ),
  };

  const leadUpdationMapper = {
    [LeadPersonalizationSource.LinkedIn]: (
      <Text textAlign="center" fontSize="sm">
        Enter your recipient's LinkedIn profile username or{" "}
        <a
          className={tw(`underline text-blue-600 cursor-pointer font-semibold`)}
          onClick={() => setSource(LeadPersonalizationSource.Website)}
        >
          switch to website
        </a>
      </Text>
    ),
    [LeadPersonalizationSource.Website]: (
      <Text textAlign="center" fontSize="sm">
        Enter your recipient's company domain or{" "}
        <a
          className={tw(`underline text-blue-600 cursor-pointer font-semibold`)}
          onClick={() => setSource(LeadPersonalizationSource.LinkedIn)}
        >
          switch to LinkedIn
        </a>
      </Text>
    ),
  };

  return (
    <Card mt={{ lg: 10 }} gap={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt={4} isInvalid={Boolean(errors?.[source]?.message)}>
          <Flex
            gap={3}
            flexWrap={{ base: "wrap", md: "nowrap" }}
            alignItems="flex-end"
          >
            <div className={tw(`w-full`)}>
              {leadSourceComponentMapper[source]}
            </div>

            <Button
              variant="brand"
              fontWeight="500"
              fontSize="sm"
              type="submit"
              minW="140px"
              leftIcon={<MdEmail />}
              isLoading={isCreatingLead}
              loadingText="Writing"
            >
              Personalize
            </Button>
          </Flex>

          <FormErrorMessage>{errors?.[source]?.message}</FormErrorMessage>
        </FormControl>
      </form>
      {leadUpdationMapper[source]}
      {isCreatingLead && (
        <div className={tw(`flex justify-center items-center gap-2`)}>
          <Icon as={MdInfo} color="brand.500" w="25px" h="25px" />
          <Text color="brand.500">Robots on it, 1-2 min, chill! 😄 🕒 🤖</Text>
        </div>
      )}
    </Card>
  );
}

export default Linkedin;
