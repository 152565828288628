import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { FaClock } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { tw } from "twind";
import { CampaignStatus } from "../enum/campaign.enum";

interface ICamapaignCard {
  name: string;
  leadCount: number;
  id: string;
  status: CampaignStatus;
}

function CampaignCard({ id, leadCount, name, status }: ICamapaignCard) {
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const history = useHistory();
  const isEnabled = leadCount && status === CampaignStatus.Complete;
  const isProcessing = status !== CampaignStatus.Complete;

  return (
    <Card
      bg={bg}
      p={6}
      minW="250px"
      maxW="300px"
      boxShadow={cardShadow}
      className={tw(
        `transition-all delay-75 ease-in-out`,
        `${isEnabled ? "cursor-pointer" : "cursor-not-allowed"}`
      )}
      onClick={() => {
        if (isEnabled) history.push(`/leadlist/${id}`);
      }}
    >
      <Flex align="center" direction="row">
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
            mb="4px"
          >
            {name}
          </Text>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
          >
            Leads : {leadCount}
          </Text>
        </Box>
        {isProcessing && (
          <Link variant="no-hover" me="16px" ms="auto" p="0px !important">
            <Tooltip label="Processing leads" placement="top">
              <span>
                <Icon
                  as={FaClock}
                  color="secondaryGray.500"
                  h="auto"
                  w="18px"
                />
              </span>
            </Tooltip>
          </Link>
        )}
      </Flex>
    </Card>
  );
}

export default CampaignCard;
