import { Image, Spinner, Text } from "@chakra-ui/react";
import { tw } from "twind";
import logoBlack from "assets/img/layout/cold-pitch-logo-svg.svg";

function MainError() {
  return (
    <div
      className={tw(
        `h-screen w-screen flex flex-col justify-center items-center gap-10`
      )}
    >
      <Image src={logoBlack} alt="Logo" w="250px" />
      <Text fontSize="lg">
        Oopsie! Looks like something got a bit wonky. Give it a few minutes and
        try again !
      </Text>
    </div>
  );
}

export default MainError;
