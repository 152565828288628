import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { tw } from "twind";
import MessageType from "views/admin/new-email/components/steps/MessageType";
import Source from "views/admin/new-email/components/steps/Source";
import { ISelectCsvFieldsProps } from "../interfaces/campaign-component.interface";
import SelectFields from "./SelectFields";

function ImportLeadModal(props: ISelectCsvFieldsProps) {
  const { isOpen, onClose, fields, uploadedFile } = props;
  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      scrollBehavior="inside"
      size="XL"
    >
      <ModalOverlay />
      <ModalContent className={tw(`max-w-[1100px]`)}>
        <ModalHeader>Import Lead List</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Steps
            activeStep={activeStep}
            colorScheme="messenger"
            fontFamily="DM Sans"
          >
            <Step
              label="Choice of Personalization"
              description="What type of personalization you want?"
            >
              <MessageType next={nextStep} />
            </Step>
            <Step
              label="Scraping data source"
              description="What shall we use to personalize?"
            >
              <Source next={nextStep} />
            </Step>
            <Step
              label="Select CSV Fields"
              description="Match each CSV field to its corresponding column"
            >
              <SelectFields
                fields={fields}
                onClose={onClose}
                uploadedFile={uploadedFile}
              />
            </Step>
          </Steps>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ImportLeadModal;
