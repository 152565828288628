import { envConfig } from "config/env.config";
import { useEffect } from "react";
import { useGetUserQuery } from "views/auth/query/user.query";

function useFeedback() {
  const DEFAULT_SCRIPT_ID = "appzi";
  const getAppziScriptSrc = (token: string) =>
    `https://w.appzi.io/w.js?token=${token}`;
  const { data: user } = useGetUserQuery();
  const isProd = envConfig.environment === "production";

  const initialize = (token: string) => {
    if (isProd) {
      if (!document) {
        return;
      }

      const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);

      if (hasScript) {
        return;
      }

      const script = document.createElement("script");

      script.src = getAppziScriptSrc(token);
      script.id = DEFAULT_SCRIPT_ID;
      script.async = true;

      document.body.appendChild(script);
    }
  };

  const setUserId = () => {
    const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);
    if (hasScript && isProd) {
      (window as any).appziSettings = {
        userId: user?.email,
      };
    }
  };

  useEffect(() => {
    initialize(envConfig.appziToken);
  }, [envConfig.appziToken, isProd]);

  useEffect(() => {
    setUserId();
  }, [user, isProd]);
}

export default useFeedback;
