import Card from "components/card/Card";
import SelectCard from "components/selectCard/SelectCard";
import { CgRowFirst, CgRowLast } from "react-icons/cg";
import { IoGitNetworkOutline } from "react-icons/io5";
import { MessageType as PersonalizationMessageType } from "../../enum/lead.enum";
import { useLeadStore } from "../../store/lead.store";

function MessageType({ next }: { next: () => void }) {
  const { setMessageType } = useLeadStore((state) => state);

  function handleSetSource(messageType: PersonalizationMessageType) {
    setMessageType(messageType);
    next();
  }

  return (
    <Card mt={{ lg: 10 }} gap={5}>
      <SelectCard
        title="First Line"
        body="Craft a tailored opening that makes your prospects feel valued"
        icon={CgRowFirst}
        onClick={() => handleSetSource(PersonalizationMessageType.FirstLine)}
      />
      <SelectCard
        title="P.S. (Post Script)"
        body="Use a personalized P.S. for added uniqueness"
        icon={CgRowLast}
        onClick={() => handleSetSource(PersonalizationMessageType.PS)}
      />
      <SelectCard
        title="Combined Approach"
        body="Use both for a memorable impression on your prospects"
        icon={IoGitNetworkOutline}
        onClick={() =>
          handleSetSource(PersonalizationMessageType.FirstLineAndPS)
        }
      />
    </Card>
  );
}

export default MessageType;
