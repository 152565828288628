import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { QueryService } from "services/query.service";
import { createBulkLead, createLead, getLeadById } from "../api/lead.api";
import { CampaignQueryEnum } from "views/admin/campaigns/query/campaign.query";

export enum LeadQueryEnum {
  GET_LEAD_BY_ID = "get-lead-by-id",
}

export const useCreateLeadQuery = ({
  history,
  resetForm,
}: {
  history: any;
  resetForm: () => void;
}) => {
  const { mutate, isLoading } = useMutation(createLead, {
    onSuccess: (data) => {
      history.push(`/lead/${data?._id}`);
    },
    onError: (error: any) => {
      resetForm();
      toast(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while creating leads",
        {
          type: "error",
        }
      );
    },
  });

  return { createLead: mutate, isCreatingLead: isLoading };
};

export const useCreateBulkLeadQuery = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const queryClient = QueryService.getQueryClient();
  const { mutate, isLoading } = useMutation(createBulkLead, {
    onSuccess: () => {
      queryClient.invalidateQueries(CampaignQueryEnum.GET_ALL_CAMPAIGN, {
        exact: true,
      });
      toast("Imported successfully", { type: "success" });
      onClose();
    },
    onError: (error: any) => {
      toast(error.message || "Something went wrong while creating leads", {
        type: "error",
      });
    },
  });

  return { addLeadQueue: mutate, isAddingLeadQueue: isLoading };
};

export const useGetLeadByIdQuery = ({ id }: { id: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [LeadQueryEnum.GET_LEAD_BY_ID, id],
    queryFn: () => getLeadById(id),
    initialData: null,
    retry: 3,
    refetchOnWindowFocus: false,
  });
  return { data, isLoading };
};
