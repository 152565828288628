import { Icon } from "@chakra-ui/react";
import Card from "components/card/Card";
import { IconType } from "react-icons/lib";
import { SiMinutemailer } from "react-icons/si";
import { tw } from "twind";

interface ISelectCard {
  icon: IconType;
  title: string;
  body: string;
  onClick: () => void;
}

function SelectCard(props: ISelectCard) {
  return (
    <Card
      border="1px"
      borderColor="gray.200"
      display="flex"
      flexDirection="row"
      gap={{ base: 3, lg: 5 }}
      className={tw(`hover:shadow-md ease-out duration-200 cursor-pointer`)}
      onClick={props?.onClick}
    >
      <div
        className={tw(
          `border-[0.5px] rounded-full border-gray-300 flex justify-center items-center h-full p-3`
        )}
      >
        <Icon as={props?.icon} w={5} h={5} color="brand.500" />
      </div>
      <div className={tw(`text-left`)}>
        <p className={tw(`font-medium`)}>{props?.title}</p>
        <p className={tw(`text-sm text-gray-500`)}>{props.body}</p>
      </div>
    </Card>
  );
}

export default SelectCard;
