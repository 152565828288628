export const prices = [
  {
    name: "Starter",
    price: 29,
    features: ["300 credits", "0.09¢ per lead", "LinkedIn Personalizations"],
    value: "starter",
    credits: 300,
  },
  {
    name: "Plus",
    price: 59,
    features: ["1000 credits", "0.05¢ per lead", "LinkedIn Personalizations"],
    value: "plus",
    credits: 1000,
    popular: true,
  },
  {
    name: "Lifetime Access",
    features: [
      "200 credits per month",
      "LinkedIn Personalizations",
      "In-Depth Scraping"
    ],
    value: "lifetime",
    price: 149,
    credits: 200,
    intent: "lifetime",
  },
];
