import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  generateCsvFile,
  getAllCampaigns,
  getCampaignById,
} from "../api/campaign.api";

export enum CampaignQueryEnum {
  GET_ALL_CAMPAIGN = "get-all-campaign",
  GET_CAMPAIGN_BY_ID = "get-campaign-by-id",
}

export const useGetAllCampaignsQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: CampaignQueryEnum.GET_ALL_CAMPAIGN,
    queryFn: getAllCampaigns,
  });
  return { data, isLoading };
};

export const useGetCampaignByIdQuery = ({ id }: { id: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [CampaignQueryEnum.GET_CAMPAIGN_BY_ID, id],
    queryFn: () => getCampaignById(id),
    initialData: null,
    retry: 3,
    refetchOnWindowFocus: false,
  });
  return { data, isLoading };
};

export const useGenerateCsvQuery = ({ setSuccess }: { setSuccess: any }) => {
  const { mutate, isLoading } = useMutation(generateCsvFile, {
    onSuccess: (data) => {
      // console.log(data);
      setSuccess(true);
    },
    onError: (error: any) => {
      toast(error.message || "Something went wrong while generating csv", {
        type: "error",
      });
    },
  });

  return { generateCsv: mutate, isGeneratingCsv: isLoading };
};
