import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  FUNDING,
  PayPalButtons,
  PayPalHostedField,
  PayPalHostedFieldsProvider,
  usePayPalHostedFields,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IPrice } from "../interfaces/price.interface";
import { useCreateTransactionQuery } from "../query/pricing.query";

interface IPaymentModal {
  onClose: () => void;
  isOpen: boolean;
  selectedPrice: IPrice | null;
}

function PaymentModal({ onClose, isOpen, selectedPrice }: IPaymentModal) {
  const [{ isPending }] = usePayPalScriptReducer();
  const history = useHistory();

  const { createTransaction, isCreatingTransaction } =
    useCreateTransactionQuery({ history });

  // creates a paypal order
  const createOrder: any = (_: any, actions: any) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: JSON.stringify({
              creditsPurchased: selectedPrice?.credits,
              intent: selectedPrice?.intent,
            }),
            amount: {
              currency_code: "USD",
              value: selectedPrice?.price,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderId: string) => {
        // console.log(orderId);
        return orderId;
      });
  };

  // check Approval
  const onApprove = async (_: any, actions: any) => {
    return actions.order
      .capture()
      .then((details: any) => {
        const transactionId = details?.id;
        const status = details?.status;
        const creditsData = JSON.parse(
          details?.purchase_units?.[0]?.description
        );
        const amount = details?.purchase_units?.[0]?.amount?.value;
        const intentData = { payer: details?.payer };
        const payload = {
          transactionId,
          status,
          creditsPurchased: Number(creditsData?.creditsPurchased),
          intent: creditsData?.intent,
          amount,
          intentData,
        };
        createTransaction(payload);
      })
      .catch((err: any) => {
        toast(err.message || "Something went wrong while transacting", {
          type: "error",
        });
        console.log(err);
      });
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Secure Payment</ModalHeader>
        <ModalBody>
          {isPending && (
            <Flex justifyContent="center">
              <Spinner color="brand.500" size="md" />
            </Flex>
          )}
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={(err: any) => {
              toast(err.message || "Something went wrong while transacting", {
                type: "error",
              });
              console.error("Paypal transaction error", err);
            }}
          />
          {isCreatingTransaction && (
            <Flex justifyContent="center">
              <Spinner color="brand.500" size="md" />
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Text textAlign="center" fontSize="sm">
            If you encounter any payment-related issues, kindly reach out to us
            via email at{" "}
            <a
              href="mailto:mithun@retale.agency"
              style={{ fontWeight: "bold" }}
            >
              mithun@retale.agency
            </a>
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PaymentModal;
