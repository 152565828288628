// Chakra imports
import { Box, Portal, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
import MainError from "components/mainError/MainError";
import MainLoader from "components/mainLoader/MainLoader";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import useClaimRedemption from "views/admin/hook/useClaimRedemption";
import useFeedback from "views/admin/hook/useFeedback";
import useSessionReplay from "views/admin/hook/useSessionReplay";
import { useGetUserQuery } from "views/auth/query/user.query";
import { useAuthStore } from "views/auth/store/auth.store";

function SignedRoute({ children }: { children: React.ReactNode }) {
  const isSignedIn = useAuthStore((state) => state?.session?.user?.id);
  const isSessionLoaded = useAuthStore((state) => state?.isSessionLoaded);
  const { data: user, isFetched } = useGetUserQuery();

  if (!isSessionLoaded) return <MainLoader />;
  else if (isSessionLoaded && !isSignedIn)
    return <Redirect to="/auth/sign-in" />;
  else if (!isFetched) return <MainLoader />;
  else if (!user && isFetched) return <MainError />;
  return <>{children}</>;
}

export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  // Intialize session replay
  useSessionReplay();
  useFeedback();
  useClaimRedemption();

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Dashboard";
    const { pathname } = window.location;

    // Check for exact path match
    const exactMatch = routes.find((route) => route.path === pathname);
    if (exactMatch) {
      return exactMatch?.heading || exactMatch?.name;
    }

    // Check for path match with a parameter (e.g., /lead/:id)
    const paramMatch = routes.find((route) => {
      const pathRegex = new RegExp(`^${route.path.replace(/:\w+/g, "\\w+")}$`);
      return pathRegex.test(pathname);
    });
    if (paramMatch) {
      return paramMatch?.heading || paramMatch?.name;
    }

    // Check for match based on layout and path
    const layoutAndPathMatch = routes.find(
      (route) => pathname.indexOf(route.layout + route.path) !== -1
    );
    if (layoutAndPathMatch) {
      return layoutAndPathMatch?.heading || layoutAndPathMatch?.name;
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    const { pathname } = window.location;

    // Check for exact path match
    const exactMatch = routes.find((route) => route.path === pathname);
    if (exactMatch) {
      return exactMatch?.secondary;
    }

    // Check for path match with a parameter (e.g., /lead/:id)
    const paramMatch = routes.find((route) => {
      const pathRegex = new RegExp(`^${route.path.replace(/:\w+/g, "\\w+")}$`);
      return pathRegex.test(pathname);
    });
    if (paramMatch) {
      return paramMatch?.secondary;
    }

    // Check for match based on layout and path
    const layoutAndPathMatch = routes.find(
      (route) => pathname.indexOf(route.layout + route.path) !== -1
    );
    if (layoutAndPathMatch) {
      return layoutAndPathMatch?.secondary;
    }

    return false;
  };

  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    const { pathname } = window.location;

    // Check for exact path match
    const exactMatch = routes.find((route) => route.path === pathname);
    if (exactMatch) {
      return exactMatch?.name;
    }

    // Check for path match with a parameter (e.g., /lead/:id)
    const paramMatch = routes.find((route) => {
      const pathRegex = new RegExp(`^${route.path.replace(/:\w+/g, "\\w+")}$`);
      return pathRegex.test(pathname);
    });
    if (paramMatch) {
      return paramMatch?.name;
    }

    // Check for match based on layout and path
    const layoutAndPathMatch = routes.find(
      (route) => pathname.indexOf(route.layout + route.path) !== -1
    );
    if (layoutAndPathMatch) {
      return layoutAndPathMatch?.name;
    }

    return false;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "") {
        return (
          <Route
            path={route.path}
            component={route.component}
            key={key}
            exact={route?.exact}
          />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  const { onOpen } = useDisclosure();
  return (
    <SignedRoute>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: "100%", xl: "calc( 100% - 290px )" }}
            maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"Horizon UI Dashboard PRO"}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: "20px", md: "30px" }}
                pe="20px"
                minH="100vh"
                pt="50px"
              >
                <Switch>{getRoutes(routes)}</Switch>
              </Box>
            ) : null}
            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </SignedRoute>
  );
}
