import { Image, Spinner } from "@chakra-ui/react";
import { tw } from "twind";
import logoBlack from "assets/img/layout/cold-pitch-logo-svg.svg";

function MainLoader() {
  return (
    <div
      className={tw(
        `h-screen w-screen flex flex-col justify-center items-center gap-10`
      )}
    >
      <Image src={logoBlack} alt="Logo" w="250px" />
      <Spinner color="brand.500" size="lg" />
    </div>
  );
}

export default MainLoader;
