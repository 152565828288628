// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import logoBlack from "assets/img/layout/cold-pitch-logo.png";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Image src={logoBlack} alt="Logo" mb={5} ml={{ lg: -4 }} />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
