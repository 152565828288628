export enum LeadStatus {
  Complete = "complete",
  Pending = "pending",
  Generating = "generating",
  Failed = "failed",
}

export enum CtaType {
  BookMeeting = "book-meeting",
  ClickLink = "click-link",
  AskQuestion = "ask-question",
  GaugeInterest = "gauge-interest",
}

export enum LeadMessageType {
  PersonalisedEmail = "personalised-email",
  EntireEmail = "entire-email",
}

export enum LeadPersonalizationSource {
  LinkedIn = "linkedin",
  Website = "website",
}

export enum MessageType {
  FirstLine = "first-line",
  PS = "post-script",
  FirstLineAndPS = "first-line-and-post-script",
}
