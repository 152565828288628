import {
  Box,
  Button,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { IPrice } from "../interfaces/price.interface";
import PriceWrapper from "./PriceWrapper";

function PricingCell(
  props: IPrice & { onClick: () => void; disabled?: boolean }
) {
  const popularBg = useColorModeValue("brand.100", "brand.300");
  const popularColor = useColorModeValue("gray.900", "gray.300");
  const featuresBg = useColorModeValue("gray.50", "gray.700");

  if (props?.popular) {
    return (
      <PriceWrapper>
        <Box position="relative">
          <Box
            position="absolute"
            top="-16px"
            left="50%"
            style={{ transform: "translate(-50%)" }}
          >
            <Text
              textTransform="uppercase"
              bg={popularBg}
              px={3}
              py={1}
              color={popularColor}
              fontSize="sm"
              fontWeight="600"
              rounded="xl"
            >
              Most Popular
            </Text>
          </Box>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              {props?.name}
            </Text>
            <HStack justifyContent="center">
              {typeof props?.price === "number" && (
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
              )}
              <Text fontSize="5xl" fontWeight="900">
                {props?.price}
              </Text>
            </HStack>
          </Box>
          <VStack bg={featuresBg} py={4} borderBottomRadius={"xl"}>
            <List spacing={3} textAlign="start" px={12}>
              {props?.features?.map((feat, idx) => (
                <ListItem key={idx}>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  {feat}
                </ListItem>
              ))}
            </List>
            <Box w="80%" pt={7}>
              {props?.cta ? (
                <Button w="full" colorScheme="brand">
                  {props?.ctaValue}
                </Button>
              ) : (
                <Button
                  w="full"
                  colorScheme="brand"
                  onClick={props?.onClick}
                  disabled={props?.disabled}
                >
                  Select
                </Button>
              )}
            </Box>
          </VStack>
        </Box>
      </PriceWrapper>
    );
  }
  return (
    <PriceWrapper>
      <Box py={4} px={12}>
        <Text fontWeight="500" fontSize="2xl">
          {props?.name}
        </Text>
        <HStack justifyContent="center">
          {typeof props?.price === "number" && (
            <Text fontSize="3xl" fontWeight="600">
              $
            </Text>
          )}
          <Text fontSize="5xl" fontWeight="900">
            {props?.price}
          </Text>
        </HStack>
      </Box>
      <VStack bg={featuresBg} py={4} borderBottomRadius={"xl"}>
        <List spacing={3} textAlign="start" px={12}>
          {props.features?.map((feat, idx) => (
            <ListItem key={idx}>
              <ListIcon as={FaCheckCircle} color="green.500" />
              {feat}
            </ListItem>
          ))}
        </List>
        <Box w="80%" pt={7}>
          {props?.cta ? (
            <Button w="full" colorScheme="brand">
              <a href="mailto:mithun@retale.agency">Contact us</a>
            </Button>
          ) : (
            <Button
              w="full"
              colorScheme="brand"
              onClick={props?.onClick}
              disabled={props?.disabled}
            >
              Select
            </Button>
          )}
        </Box>
      </VStack>
    </PriceWrapper>
  );
}

export default PricingCell;
