import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger as OrigPopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Fragment, useLayoutEffect, useMemo, useRef, useState } from "react";
import { tw } from "twind";
import { ILeadFLPSMessage } from "views/admin/leads/interfaces/lead.interface";
export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

interface IMessageSelector {
  messages: string[] | ILeadFLPSMessage[];
}

function MessageSelector(props: IMessageSelector) {
  const selectRef = useRef(null);

  const [selectW, setSelectW] = useState("100%");
  const [perMsgIdx, setPerMsgIdx] = useState(0);

  const selectedMessage = props?.messages?.[perMsgIdx];

  const modifiedText: string = useMemo(() => {
    if (typeof selectedMessage === "string") return selectedMessage;
    else if (typeof selectedMessage === "object")
      return `${selectedMessage?.firstLine}\n\nP.S. ${selectedMessage?.ps}`;
  }, [selectedMessage]);

  const { isOpen, onToggle, onClose } = useDisclosure();

  function handleSelectW() {
    const width = selectRef?.current?.offsetWidth;
    setSelectW(`${width}px`);
  }

  function handleSelectMessage(idx: number) {
    setPerMsgIdx(idx);
    onClose();
  }

  function renderModifiedText(msg: string | ILeadFLPSMessage) {
    if (typeof msg === "string") return msg;
    else if (typeof msg === "object")
      return `${msg?.firstLine}\n\nP.S. ${msg?.ps}`;
  }

  useLayoutEffect(() => {
    handleSelectW();
  }, [props?.messages?.length]);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      flip={false}
      preventOverflow={false}
      strategy="fixed"
    >
      <PopoverTrigger>
        <Flex
          alignItems="center"
          gap={5}
          p={6}
          bg="#f4f1ff"
          borderRadius="lg"
          border={1}
          borderColor="gray.300"
          borderStyle="solid"
          cursor="pointer"
          ref={selectRef}
          onClick={onToggle}
        >
          <Text>{modifiedText}</Text>
          <ChevronDownIcon />
        </Flex>
      </PopoverTrigger>
      <PopoverContent p={3} h="300px" overflow="auto" w={selectW}>
        {props?.messages?.map((msg, idx) => (
          <Fragment key={idx}>
            <Text
              my={3}
              p={3}
              className={tw(
                `hover:bg-[#f4f1ff] rounded-lg transition-all delay-50 cursor-pointer ${
                  idx === perMsgIdx ? "bg-[#f4f1ff]" : "bg-white"
                }`
              )}
              onClick={() => handleSelectMessage(idx)}
            >
              {renderModifiedText(msg)}
            </Text>
            <Divider />
          </Fragment>
        ))}
      </PopoverContent>
    </Popover>
  );
}

export default MessageSelector;
