import { Box, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypalConfig } from "config/paypal.config";
import { useMemo, useState } from "react";
import { useGetUserQuery } from "views/auth/query/user.query";
import PaymentModal from "./components/PaymentModal";
import PricingCell from "./components/PricingCell";
import { IPrice } from "./interfaces/price.interface";
import { prices } from "./utils/prices.util";

export default function Pricing() {
  const [selectedPrice, setSelectedPrice] = useState<IPrice | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: user } = useGetUserQuery();

  const paypalProviderOptions = {
    clientId: paypalConfig.clientId,
    currency: "USD",
    intent: "capture",
    components: "hosted-fields,buttons",
  };

  const updatedPrices = useMemo(() => {
    const mappedPrices = prices.map((price) => {
      if (price?.intent === "lifetime" && user?.intent === "lifetime")
        return { ...price, disabled: true };
      return { ...price };
    });
    return mappedPrices;
  }, [user, prices]);

  function handlePriceSelection(data: IPrice) {
    setSelectedPrice(data);
    onOpen();
  }

  return (
    <Box py={12} pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
      <VStack spacing={2} textAlign="center">
        <Text as="h1" fontSize="4xl" fontWeight="bold">
          Plans that fit your need
        </Text>
        <Text fontSize="lg" color={"gray.500"}>
          Enlist our AI to amplify responses, facilitate extra meetings, and
          foster business growth –{" "}
          <Text variant="span" fontWeight="bold">
            All without credit expiration!
          </Text>
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        {updatedPrices.map((data, idx) => (
          <PricingCell
            key={idx}
            onClick={() => handlePriceSelection(data)}
            {...data}
          />
        ))}
      </Stack>
      <PayPalScriptProvider options={{ ...paypalProviderOptions }}>
        <PaymentModal
          isOpen={isOpen}
          onClose={onClose}
          selectedPrice={selectedPrice}
        />
      </PayPalScriptProvider>
    </Box>
  );
}
