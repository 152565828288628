import {
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { LeadFieldFormState } from "../../interfaces/lead.interface";
import { linkedinRegex } from "../../utils/string.util";
import { IoMdInformationCircle } from "react-icons/io";

function LinkedinField({ formState }: { formState: LeadFieldFormState }) {
  const { register } = formState;

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const tooltipText =
    "This feature extracts insights from your recipient's LinkedIn profile to create personalized opening lines for your emails, boosting response rates by 8x.";
  return (
    <>
      <FormLabel
        display="flex"
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        mb="8px"
      >
        LinkedIn Profile Username<Text color={brandStars}>*</Text>
        <Tooltip label={tooltipText} hasArrow placement="right">
          <span>
            <Icon as={IoMdInformationCircle} w="18px" h="auto" ml={2} />
          </span>
        </Tooltip>
      </FormLabel>
      <InputGroup>
        <InputLeftAddon
          children="linkedin.com/in/"
          fontSize="sm"
          fontWeight="500"
        />
        <Input
          placeholder="satyanadella"
          fontSize="sm"
          fontWeight="500"
          {...register("linkedin", {
            required: "LinkedIn username required !",
            pattern: {
              value: linkedinRegex,
              message: "Danger, Invalid LinkedIn username alert! 🤖",
            },
          })}
        />
      </InputGroup>
    </>
  );
}

export default LinkedinField;
