import { Box, Spinner } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { useGetCreditsQuery } from "views/admin/credits/query/credits.query";

function CreditProvider({ children }: { children: ReactNode }) {
  const { data, isLoading } = useGetCreditsQuery();
  if (isLoading || !data)
    return (
      <Box
        pt={{ base: "100px", md: "80px", xl: "80px" }}
        display="flex"
        justifyContent="center"
        mt={5}
      >
        <Spinner color="brand.500" size="lg" />
      </Box>
    );
  else if (data?.available <= 0) return <Redirect to="/pricing" />;
  return <>{children}</>;
}

export default CreditProvider;
