import { axiosInstance } from "utils/axios.util";
import { ICreateLeadPayload, ILead } from "../interfaces/lead.interface";

export async function createLead(payload: ICreateLeadPayload) {
  const { data } = await axiosInstance({
    method: "POST",
    url: `/lead`,
    data: payload,
  });
  return data;
}

export async function createBulkLead(payload: FormData) {
  const { data } = await axiosInstance({
    method: "POST",
    url: `/lead/bulk`,
    data: payload,
  });
  return data as { campaignId: string };
}

export async function getLeadById(id: string) {
  const { data } = await axiosInstance({
    method: "GET",
    url: `/lead/${id}`,
  });
  return data as ILead;
}
