import { axiosInstance } from "utils/axios.util";
import { ICampaign } from "../interfaces/campaign.interface";

export async function getAllCampaigns() {
  const { data } = await axiosInstance({
    method: "GET",
    url: `/campaign`,
  });
  return data as ICampaign[];
}

export async function getCampaignById(id: string) {
  const { data } = await axiosInstance({
    method: "GET",
    url: `/campaign/${id}`,
  });
  return data as ICampaign;
}

export async function generateCsvFile(id: string) {
  const { data } = await axiosInstance({
    method: "POST",
    url: `/lead/generate/csv`,
    data: { campaignId: id },
  });
  return data as { id: string };
}
