import {
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoMdInformationCircle } from "react-icons/io";
import { LeadFieldFormState } from "../../interfaces/lead.interface";
import { websiteRegex } from "../../utils/string.util";

function WebsiteField({ formState }: { formState: LeadFieldFormState }) {
  const { register } = formState;

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const tooltipText =
    "This feature gathers information from your recipient's company website to compose personalized opening lines for your emails, resulting in an 8x increase in response rates.";
  return (
    <>
      <FormLabel
        display="flex"
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        mb="8px"
      >
        Website URL<Text color={brandStars}>*</Text>
        <Tooltip label={tooltipText} hasArrow placement="right">
          <span>
            <Icon as={IoMdInformationCircle} w="18px" h="auto" ml={2} />
          </span>
        </Tooltip>
      </FormLabel>
      <InputGroup>
        <InputLeftAddon children="https://" fontSize="sm" fontWeight="500" />
        <Input
          placeholder="garyvaynerchuk.com"
          fontSize="sm"
          fontWeight="500"
          {...register("website", {
            required: "Website URL required !",
            pattern: {
              value: websiteRegex,
              message: "Danger, Invalid URL alert! 🤖",
            },
          })}
        />
      </InputGroup>
    </>
  );
}

export default WebsiteField;
