import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import CreditProvider from "layouts/admin/CreditProvider";
import Papa from "papaparse";
import { ChangeEventHandler, Fragment, useRef, useState } from "react";
import { toast } from "react-toastify";
import { tw } from "twind";
import { useGetCreditsQuery } from "../credits/query/credits.query";
import CampaignCard from "./components/CampaignCard";
import ImportLeadModal from "./components/ImportLeadModal";
import { useGetAllCampaignsQuery } from "./query/campaign.query";

function Campaigns() {
  const { data: campaigns, isLoading: isCampaignsLoading } =
    useGetAllCampaignsQuery();
  const { data: credits } = useGetCreditsQuery();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const quickPersonalizationLead = campaigns?.find((cam) => cam?.default);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<File>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (event) => {
    setUploadedFile(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const leadCount = results?.data?.length;
        if (leadCount > credits?.available) {
          toast(
            "You don't have enough credits to import the lead list. Please buy credits.",
            {
              type: "error",
            }
          );
        } else {
          setCsvFields(results?.meta?.fields);
          onOpen();
        }
      },
    });
  };

  if (isCampaignsLoading)
    return (
      <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
        <Box maxW={{ lg: "850px" }} m="auto">
          <Card
            gap={5}
            p={5}
            fontWeight={500}
            mt={5}
            display="flex"
            justifyContent="center"
          >
            <Text textAlign="center" fontSize="lg" fontWeight="bold">
              Hold tight! Info's en route! 🚀
            </Text>
            <Flex justifyContent="center">
              <Spinner color="brand.500" size="md" />
            </Flex>
          </Card>
        </Box>
      </Box>
    );

  return (
    <CreditProvider>
      <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
        <Card p={10}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb={10}
            ml={3}
          >
            Quick Personalization
          </Text>
          {quickPersonalizationLead && (
            <CampaignCard
              key={quickPersonalizationLead?._id}
              name={quickPersonalizationLead?.name}
              leadCount={quickPersonalizationLead?.leads?.length}
              id={quickPersonalizationLead?._id}
              status={quickPersonalizationLead?.status}
            />
          )}
          <Flex mt={20} mb={10} ml={3} gap={5} justify="space-between">
            <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
              Lead Lists
            </Text>
            <Button
              variant="brand"
              fontWeight="500"
              fontSize="sm"
              type="submit"
              loadingText="Uploading"
              width="min-content"
              onClick={() => inputFileRef?.current?.click()}
            >
              Import Leads
            </Button>
          </Flex>

          {campaigns.length > 1 ? (
            <Flex gap={10} justifyContent="flex-start" wrap="wrap">
              {campaigns?.map((cam) => (
                <Fragment key={cam._id}>
                  {!cam?.default && (
                    <CampaignCard
                      key={cam?._id}
                      name={cam?.name}
                      leadCount={cam?.leads?.length}
                      id={cam?._id}
                      status={cam?.status}
                    />
                  )}
                </Fragment>
              ))}
            </Flex>
          ) : (
            <Text
              fontWeight="500"
              color={textColorSecondary}
              fontSize="sm"
              me="4px"
              textAlign="center"
            >
              Uh-oh! Looks like your lead list is feeling a bit lonely and
              deserted.&nbsp;{" "}
              <a
                className={tw(`underline text-blue-600 cursor-pointer`)}
                onClick={() => inputFileRef?.current?.click()}
              >
                Please add leads!
              </a>
            </Text>
          )}
        </Card>
        <input
          type="file"
          name="csv-file"
          className={tw(`hidden`)}
          ref={inputFileRef}
          onChange={handleFileUpload}
          accept=".csv"
        />
        <ImportLeadModal
          isOpen={isOpen}
          onClose={onClose}
          fields={csvFields}
          uploadedFile={uploadedFile}
        />
      </Box>
    </CreditProvider>
  );
}

export default Campaigns;
