export const linkedinRegex =
  /^(?:(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/)?([a-zA-Z0-9_-]+)(?:\/)?$/;

export const websiteRegex =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/)?$/;

export function extractLinkedInUsername(url: string): string | null {
  const match = url.match(linkedinRegex);
  return match ? match[1] : null;
}
