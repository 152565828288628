import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { tw } from "twind";
import { useCreateBulkLeadQuery } from "views/admin/leads/query/lead.query";
import { LeadPersonalizationSource } from "views/admin/new-email/enum/lead.enum";
import { useLeadStore } from "views/admin/new-email/store/lead.store";
import { ISelectCsvFieldsForm } from "../interfaces/campaign-component.interface";

function SelectFields({
  onClose,
  fields,
  uploadedFile,
}: {
  onClose: () => void;
  fields: string[];
  uploadedFile: File;
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { source: leadSource, messageType } = useLeadStore((state) => state);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ISelectCsvFieldsForm>();

  const { addLeadQueue, isAddingLeadQueue } = useCreateBulkLeadQuery({
    onClose,
  });

  async function onSubmit(data: ISelectCsvFieldsForm) {
    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("dataField", data?.dataField);
    formData.append("campaignName", data?.campaignName);
    formData.append("source", leadSource);
    formData.append("messageType", messageType);
    formData.append("nameField", data?.nameField);
    formData.append("emailField", data?.emailField);
    addLeadQueue(formData);
  }

  const dataFieldTextMapper = {
    [LeadPersonalizationSource.LinkedIn]: "LinkedIn Username Field",
    [LeadPersonalizationSource.Website]: "Company Website URL Field",
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl mt={4} isInvalid={Boolean(errors?.campaignName?.message)}>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Campaign name<Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
          placeholder="Prospects Campaign"
          fontSize="sm"
          fontWeight="500"
          {...register("campaignName", {
            required: "Campaign name required !",
          })}
        />
        <FormErrorMessage>{errors?.campaignName?.message}</FormErrorMessage>
      </FormControl>

      <FormControl mt={4} isInvalid={Boolean(errors?.dataField?.message)}>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          {dataFieldTextMapper[leadSource] ??
            dataFieldTextMapper[LeadPersonalizationSource.LinkedIn]}
          <Text color={brandStars}>*</Text>
        </FormLabel>
        <Select
          placeholder={`Select ${dataFieldTextMapper[leadSource]}`}
          fontSize="sm"
          {...register("dataField", {
            required: `${dataFieldTextMapper[leadSource]} required !`,
          })}
        >
          {fields?.map((field, idx) => (
            <option key={idx} value={field}>
              {field}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors?.dataField?.message}</FormErrorMessage>
      </FormControl>

      <FormControl mt={4} isInvalid={Boolean(errors?.nameField?.message)}>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Name Field
        </FormLabel>
        <Select
          placeholder="Select Name Field"
          fontSize="sm"
          {...register("nameField")}
        >
          {fields?.map((field, idx) => (
            <option key={idx} value={field}>
              {field}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors?.nameField?.message}</FormErrorMessage>
      </FormControl>

      <FormControl mt={4} isInvalid={Boolean(errors?.emailField?.message)}>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Email Field
        </FormLabel>
        <Select
          placeholder="Select Email Field"
          fontSize="sm"
          {...register("emailField")}
        >
          {fields?.map((field, idx) => (
            <option key={idx} value={field}>
              {field}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors?.emailField?.message}</FormErrorMessage>
      </FormControl>
      <div className={tw(`flex w-full justify-end mt-3`)}>
        <Button
          variant="brand"
          fontWeight="500"
          fontSize="sm"
          type="submit"
          loadingText="Importing"
          isLoading={isAddingLeadQueue}
          className={tw(`!min-w-[6rem]`)}
        >
          Import
        </Button>
      </div>
    </form>
  );
}

export default SelectFields;
