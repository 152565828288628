import {
  Divider,
  Icon,
  Text,
  Tooltip,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { tw } from "twind";
import { ILeadFLPSMessage } from "views/admin/leads/interfaces/lead.interface";
import { MessageType } from "views/admin/new-email/enum/lead.enum";

interface IMessageSelector {
  messages: string[] | ILeadFLPSMessage[];
  messageType: MessageType;
}

function MessageDisplayer({ messages, messageType }: IMessageSelector) {
  return (
    <div>
      {messages.map((msg, idx) => {
        return (
          <MessageListItem
            message={msg}
            showDivider={idx !== messages.length - 1}
            messageType={messageType}
            key={idx}
          />
        );
      })}
    </div>
  );
}

function MessageListItem({
  showDivider,
  message,
  messageType,
}: {
  showDivider: boolean;
  message: string | ILeadFLPSMessage;
  messageType: MessageType;
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const copyText: string = useMemo(() => {
    if (typeof message === "string") return message;
    else if (typeof message === "object")
      return `${message?.firstLine}\n\nP.S. ${message?.ps}`;
  }, [message]);

  const renderMessage = useCallback(() => {
    if (messageType === MessageType.FirstLine && typeof message === "string") {
      return <Text color={textColor}>{message}</Text>;
    } else if (messageType === MessageType.PS && typeof message === "string") {
      return <Text color={textColor}>P.S. {message}</Text>;
    } else if (
      messageType === MessageType.FirstLineAndPS &&
      typeof message === "object"
    ) {
      return (
        <div>
          <Text color={textColor}>{message?.firstLine}</Text>
          <Text mt={2} color={textColor}>
            P.S. {message?.ps}
          </Text>
        </div>
      );
    }
  }, [message, messageType, MessageType]);

  const { onCopy, hasCopied } = useClipboard(copyText);
  const tooltipText = hasCopied ? "Copied!" : "Copy to clipboard";
  return (
    <>
      <div className={tw(`flex gap-5 mx-5 my-8 justify-between`)}>
        {renderMessage()}
        <Tooltip
          label={tooltipText}
          placement="top"
          bg="brand.200"
          closeOnClick={false}
        >
          <span>
            <Icon
              as={IoCopyOutline}
              color="brand.500"
              w="25px"
              h="25px"
              cursor="pointer"
              onClick={onCopy}
            />
          </span>
        </Tooltip>
      </div>
      {showDivider && <Divider orientation="horizontal" colorScheme="red" />}
    </>
  );
}

export default MessageDisplayer;
