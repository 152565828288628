import { Box } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import CreditProvider from "layouts/admin/CreditProvider";
import Linkedin from "./components/steps/Linkedin";
import MessageType from "./components/steps/MessageType";
import Source from "./components/steps/Source";

function NewEmail() {
  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });
  return (
    <CreditProvider>
      <Box pt={{ base: "100px", md: "80px", xl: "80px" }} mt={5}>
        <Box maxW={{ lg: "1200px" }} m="auto">
          <Steps
            activeStep={activeStep}
            colorScheme="messenger"
            fontFamily="DM Sans"
          >
            <Step
              label="Choice of Personalization"
              description="What type of personalization you want?"
            >
              <MessageType next={nextStep} />
            </Step>
            <Step
              label="Scraping data source"
              description="What shall we use to personalize?"
            >
              <Source next={nextStep} />
            </Step>
            <Step
              label="Person you're targeting"
              description="Prospect's LinkedIn / Company website"
            >
              <Linkedin />
            </Step>
          </Steps>
        </Box>
      </Box>
    </CreditProvider>
  );
}

export default NewEmail;
